import Navigation from "../navbar";

const Layout = ({children}) => {
    return (
        <>
            <Navigation />
            <main>
                {children}
            </main>
            <footer/>
        </>
    )
}

export default Layout;