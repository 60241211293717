import React, {Fragment, useEffect} from 'react';
import {Card, Input, Button, Typography, Checkbox, Alert} from "@material-tailwind/react";
import db from '../../firebase';
import {ExclamationTriangleIcon, HandThumbUpIcon} from "@heroicons/react/24/solid";

export default function RegisterForm() {

    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [checked, setChecked] = React.useState(false);
    const [showOne, setShowOne] = React.useState(false);
    const [showTwo, setShowTwo] = React.useState(false);
    const [showElement,setShowElement] = React.useState(false)

    const handleChecked = (event) => {
        console.log(event.target.checked);
        setChecked(event.target.checked);
    }
    const handleNameChange = (event) => {
        setName(event.target.value);
    }

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }

    const handleRegister = async (event) => {
        event.preventDefault();
        if(name !== '' && email !== '') {
            setShowTwo(false);
            if (checked) {
                await (await db.collection('registers').add({})).set({
                    name: name,
                    email: email,
                });
                setName('');
                setEmail('');
                setChecked(false);
                setShowOne(false);
                setShowTwo(false);
                setShowElement(true)
            } else {
                setShowOne(true);
            }
        } else {
            setShowTwo(true);
        }
    }

    return (
        <Card className="flex flex-col rounded-3xl w-3/4 h-auto bg-teal-950 content-center items-center mx-auto mt-8 shadow-xl drop-shadow lg:w-1/3">
            <Alert className="grid items-center text-center mx-auto justify-center w-full mt-4 bg-green-700"
                    show={showElement}
                    animate={{
                        mount: {y: 0},
                        unmount: {y: 100},
                    }}
                    dismissible={{
                        onClose: () => setShowElement(false),
                    }}
            >
                <Typography className="text-white font-semibold">
                    Successfully Registered!
                    <HandThumbUpIcon className="inline-block w-5 h-5 ml-2 mb-1" aria-hidden="true"/>
                </Typography>
            </Alert>
            <Alert className="grid items-center text-center mx-auto justify-center w-full mt-4 bg-yellow-500"
                   show={showOne}
                   animate={{
                       mount: { y: 0 },
                       unmount: { y: 100 },
                   }}
                   dismissible={{
                       onClose: () => setShowOne(false),
                   }}
            >
                <Typography className="text-white font-semibold">
                    You must agree to the terms and conditions
                    <ExclamationTriangleIcon className="inline-block w-5 h-5 ml-2" aria-hidden="true" />
                </Typography>
            </Alert>
            <Alert className="grid items-center text-center mx-auto justify-center w-full mt-4 bg-yellow-500"
                    show={showTwo}
                    animate={{
                        mount: {y: 0},
                        unmount: {y: 100},
                    }}
                    dismissible={{
                        onClose: () => setShowTwo(false),

                    }}
            >
                <Typography className="text-white font-semibold">
                    Name and email required to register
                    <ExclamationTriangleIcon className="inline-block w-5 h-5 ml-2" aria-hidden="true"/>
                </Typography>
            </Alert>

            <Typography className="font-semibold text-white text-xl pt-4">
                Sign Up
            </Typography>
            <Typography className="mt-1 font-normal text-gray-200">
                Register now for early access.
            </Typography>
            <form className="flex inline-flex mt-8 mb-2 max-w-full sm:w-96" onSubmit={handleRegister}>
                <div className="mb-4 flex flex-col gap-6 text-white gap-8 items-center mx-auto">
                    <Input type="text" size="lg"  value={name} onChange={handleNameChange} placeholder="Name" className="rounded-b-lg rounded-t-lg px-2 border-2 text-gray-900 text-lg text-white"/>
                    <Input type="email" size="lg" value={email} onChange={handleEmailChange} placeholder="Email" className="rounded-b-lg rounded-t-lg px-2 border-2 text-gray-900 text-lg text-white"/>
                    <Checkbox ripple={false} value={checked} className="h-4" onChange={handleChecked} label={
                        (
                            <Typography className="text-white flex items-center font-normal px-2 py-2" variant="small">
                                I agree the
                                <a
                                    href="#"
                                    className="font-medium transition-colors hover:text-blue-300 font-semibold"
                                >
                                    &nbsp;Terms and Conditions
                                </a>
                            </Typography>
                        )
                    }
                    />
                    <Button type="submit" onClick={handleRegister} className="rounded-full w-fit bg-indigo-600 content-center mx-auto px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        Register
                    </Button>
                </div>
            </form>
        </Card>
    );
}