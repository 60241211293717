// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';;

const app = firebase.initializeApp({
    apiKey: "AIzaSyAWiFbPqJGpvv5eRkqzFNOVcxC5m3w6d-s",
    authDomain: "pitchitt-8f224.firebaseapp.com",
    databaseURL: "https://pitchitt-8f224-default-rtdb.firebaseio.com",
    projectId: "pitchitt-8f224",
    storageBucket: "pitchitt-8f224.appspot.com",
    messagingSenderId: "103253743299",
    appId: "1:103253743299:web:e3e43ddb335963686ff476",
    measurementId: "G-BDNNCL8W2M"
});

const db = app.firestore();
export default db;