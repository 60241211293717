import { useState, useEffect } from "react";
import { Navbar, MobileNav, Typography, Button, IconButton } from "@material-tailwind/react";
import logo_1 from "../../resources/logos/logo-3.png";


export default function Navigation() {
    const [openNav, setOpenNav] = useState(false);

    useEffect(() => {
        window.addEventListener(
            "resize",
            () => window.innerWidth >= 960 && setOpenNav(false)
        );
    }, []);

    const navList = (
        <ul className="mb-0 mt-0 flex flex-row gap-0.5 lg:mb-0 lg:mt-0 lg:flex lg:flex-row lg:gap-0.5">
            {/*<Typography
                as="li"
                variant="small"
                color="blue-gray"
                className="p-1 font-semibold"
            >
                <a href="#" className="flex items-center">
                    Features
                </a>
            </Typography>
            <Typography
                as="li"
                variant="small"
                color="blue-gray"
                className="p-1 font-semibold"
            >
                <a href="#" className="flex items-center">
                    Blog
                </a>
            </Typography>
            <Typography
                as="li"
                variant="small"
                color="blue-gray"
                className="p-1 font-semibold"
            >
                <a href="#" className="flex items-center">
                    Contact
                </a>
            </Typography>*/}
            <Button className="mb-8" onClick={
                () => {
                    window.open("https://www.linkedin.com/company/letspitchitt");
                }
            }>
                <img src={"https://upload.wikimedia.org/wikipedia/commons/thumb/c/ca/LinkedIn_logo_initials.png/640px-LinkedIn_logo_initials.png"} className="h-6 w-6 mx-0"/>
            </Button>
            <Button className="mb-8" onClick={
                () => {
                    window.open("https://instagram.com/pitchitt.io?igshid=Y2I2MzMwZWM3ZA==");
                }
            }>
                <img src={"https://www.freepnglogos.com/uploads/logo-ig-png/logo-ig-stunning-instagram-logo-vector-download-for-new-7.png"} className="h-6 w-6 mx-0"/>
            </Button>
        </ul>
    );

    return (
        <Navbar className="mx-auto max-w-screen-xl py-0 px-6 pb-0 lg:px-2 lg:py-2 bg-opacity-0">
            <div className="container mx-auto flex inline items-center justify-between text-white font-semibold">
                <Typography
                    as="a"
                    href="#"
                    variant="small"
                    className="mr-32 cursor-pointer py-4 font-normal"
                >
                    <span>
                        <a href="../App" className="flex items-center">
                            <img className="h-40 w-40 mx-auto" src={logo_1} alt="Your Company" />
                        </a>
                    </span>
                </Typography>
                <div className="hidden lg:block">{navList}</div>
                <IconButton
                    variant="text"
                    className="h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
                    ripple={false}
                    onClick={() => setOpenNav(!openNav)}
                >
                    {openNav ? (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            className="h-6 w-6"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    ) : (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M4 6h16M4 12h16M4 18h16"
                            />
                        </svg>
                    )}
                </IconButton>
            </div>
            <MobileNav open={openNav}>
                <div className="container mx-auto">
                    {navList}
                </div>
            </MobileNav>
        </Navbar>
    );
}