import Layout from "../components/layout";
import logo_4 from "../resources/logos/logo-4.png";
import {Button, Typography} from "@material-tailwind/react";
import Footer from "../components/footer";
import RegisterForm from "../components/register-form";
import {ArrowLongRightIcon} from "@heroicons/react/20/solid";
import {ArrowPathIcon, ChevronDoubleUpIcon} from "@heroicons/react/24/solid";
const handleLearnMore = () => {
    const element = document.getElementById('learn-more');
    if (element) {
        element.scrollIntoView({behavior: 'smooth'});
    }
}
const registerScroll = () => {
    const element = document.getElementById('register-form');
    if (element) {
        element.scrollIntoView({behavior: 'smooth'});
    }
}

    export default function Landing() {
        return (
            <div className = "h-screen w-screen flex flex-wrap bg-gradient-to-b from-gray-950 to-teal-950 overflow-y-scroll">
                <Layout>
                    <div className="flex flex-col items-center justify-center h-auto w-auto">
                        <h1 className="inline-flex text-yellow-300 text-2xl font-semibold lg:text-4xl lg:font-bold text-center mb-0">
                            Launching This Winter...
                        </h1>
                        <img className="w-5/6 h-auto lg:w-2/5" src={logo_4}/>
                    </div>
                    <div className="flex flex-col items-center justify-center mx-auto w-2/3 h-auto mb-6">
                        <Typography className="text-white text-left text-lg font-medium lg:text-xl lg:text-justify lg:font-semibold mt-4">
                            At PitchItt, we believe that everyone deserves a chance to turn their dreams into reality. That's
                            why we're committed to helping startups and small businesses get the funding they need to succeed.
                            Whether you're an entrepreneur looking to launch a new business, or an investor looking for exciting
                            new opportunities, PitchItt is the perfect platform for you. With PitchItt, entrepreneurs can
                            create a profile and showcase their business idea, along with the funding amount they are
                            seeking, and connect with a wide network of investors.<br/>
                        </Typography>
                        <Typography className="inline rounded-full text-yellow-300 text-center mx-auto text-lg font-medium lg:text-center lg:text-xl lg:font-medium mt-4">
                            Register today and stay tuned for our official
                            launch.
                        </Typography>
                        <Button variant="gradient" onClick={handleLearnMore} className="flex items-center text-center h-fit mx-auto gap-2 rounded-full w-fit my-10 text-l font-semibold bg-teal-200 bg-opacity-60 px-4 pt-1 pb-1 text-white">
                            Learn more
                        </Button>
                    </div>
                    <div className="mb-16" id="register-form">
                        <RegisterForm/>
                    </div>
                    <div id="learn-more" className="flex flex-col items-center justify-center mx-auto w-2/3 h-auto mb-6">
                        <h1 className="inline-flex text-white text-2xl font-semibold lg:text-3xl lg:font-extrabold text-center mt-16 mb-1">
                            More about pitchItt
                        </h1>
                        <Typography className="text-white text-left text-lg font-medium lg:text-justify lg:text-xl lg:font-semibold mt-3">
                            PitchItt is a revolutionary funding platform designed to help startups and small businesses
                            get the funding they need to turn their dreams into reality. With PitchItt, entrepreneurs
                            can create a profile and showcase their business idea, along with the funding amount they
                            are seeking, and connect with a wide network of investors.<br/><br/>

                            Our platform makes it easy for entrepreneurs to get the funding they need, while also
                            providing investors with a powerful tool for discovering new and exciting investment
                            opportunities. With PitchItt, startups and small businesses can access a wide range of
                            funding options, including equity crowdfunding, reward-based crowdfunding, and revenue-based
                            financing and much more.<br/><br/>

                            One of the unique features of PitchItt is our personalized approach to funding. We
                            understand that every business is different, and that's why we take the time to get to know
                            each and every entrepreneur who uses our platform. We work closely with our users to
                            understand their goals and needs, and help them through every step of the process.<br/><br/>

                            Our platform is also designed to be user-friendly and easy to navigate. Entrepreneurs can
                            quickly and easily create a profile and start showcasing their business idea to investors.
                            Investors, in turn, can browse through the various campaigns and choose to connect with the
                            businesses they find most promising. PitchItt is a powerful tool for both entrepreneurs and
                            investors as it allows them to showcase each and every aspect of their business via custom
                            fields and support for attached documents.<br/><br/>

                            At PitchItt, we believe that everyone deserves a chance to turn their dreams into reality.
                            That's why we're committed to helping startups and small businesses get the funding they
                            need to succeed. Register today for early access to our platform
                        </Typography>
                        <Button className="flex flex-col items-center mx-auto h-fit gap-1 rounded-full w-fit my-10 text-l lg:text-xl font-semibold bg-transparent bg-opacity-60 px-4 pt-1 pb-1 text-teal-200 text-opacity-80" onClick={registerScroll} ripple={false}>
                            <ChevronDoubleUpIcon className="h-6 w-6 animate-bounce"/>
                            click to register
                        </Button>

                    </div>

                    <Footer />
                </Layout>
            </div>
        )
    }