import { Typography } from "@material-tailwind/react";
import logo_1 from "../../resources/logos/logo-1.png"

export default function Footer() {
    return (
        <footer className="w-full bg-inherit p-10 align-bottom mt-auto">
            <hr className="my-1 border-b-white border-2 mb-3" />
            <div className="flex flex-col items-center justify-center gap-y-6 gap-x-12 text-center md:justify-between bg-transparent">
                {/*<ul className="flex flex-wrap items-center gap-x-8 p-0 text-gray-400">
                    <li>
                        <Typography
                            as="a"
                            href="#"
                            color="blue-gray"
                            className="font-normal transition-colors hover:text-blue-500 focus:text-blue-500"
                        >
                            About Us
                        </Typography>
                    </li>
                    <li>
                        <Typography
                            as="a"
                            href="#"
                            color="blue-gray"
                            className="font-normal transition-colors hover:text-blue-500 focus:text-blue-500"
                        >
                            License
                        </Typography>
                    </li>
                    <li>
                        <Typography
                            as="a"
                            href="#"
                            color="blue-gray"
                            className="font-normal transition-colors hover:text-blue-500 focus:text-blue-500"
                        >
                            Contribute
                        </Typography>
                    </li>
                    <li>
                        <Typography
                            as="a"
                            href="#"
                            color="blue-gray"
                            className="font-normal transition-colors hover:text-blue-500 focus:text-blue-500"
                        >
                            Contact Us
                        </Typography>
                    </li>
                </ul>*/}
            </div>
            <img src={logo_1} alt="logo-ct" className="w-20 items-center mx-auto mt-3"/>
            <Typography className="text-gray-400 text-center font-normal">
                &copy; 2023 pitchItt
            </Typography>
        </footer>
    );
}